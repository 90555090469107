import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from '@reach/router';
import CreateOrganization from 'components/CreateOrganization';
import UserDashboard from 'components/DashboardLayout/UserDashboard';
import { Box, Heading } from 'grommet';
import React from 'react';
import {
  OrganizationService,
  IOrganization,
  ICreateOrganizationPayload
} from 'services/organizations';
import tw from 'twin.macro';

const Container = tw.div`max-w-[864px] mx-auto mt-7`;
// const Divider = tw.div`w-full mx-auto h-0 my-6`;

function Create() {
  
  const [createOrganization, { loading  }] = useMutation<
    { insert_organizations_one: IOrganization },
    { newOrg: ICreateOrganizationPayload }
  >(OrganizationService.create);

 

  const handleSave = (value: ICreateOrganizationPayload) => {
    createOrganization({ variables: { newOrg: value } })
      .then((res) => {
        navigate(`/organization/dashboard/${res?.data?.insert_organizations_one?.id}`);
      })
      .catch();
  };

  return (
    <UserDashboard>
      <Container>
        <Heading level={'3'} size="medium">
          Create organization
        </Heading>
        <Box style={{ display: loading ? 'none' : 'flex' }}>
          <CreateOrganization onSave={handleSave}  />
        </Box>
        <Box style={{ display: loading ? 'flex' : 'none' }}>Loading.....</Box>
      </Container>
    </UserDashboard>
  );
}

Create.propTypes = {};

export default Create;
